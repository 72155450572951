import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import FileDragZone from "../Offer/FileDragZone";
import OfferPeriod from "../Offer/OfferPeriod";
import CloseBtn from "../../assest/images/CloseBtn.svg";
import { differenceInDays } from "date-fns";
import { Usercontext } from "../../context/UserContext";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

function AddMoreOffers({
  setIsAddOfferOpen,
  isAddOfferOpen,
  addOffer,
  editOffer,
  setSelectedOffer,
  SelectedOffer,
}) {
  const validationSchema = yup.object({
    offerName: yup.string().required("Offer name is required"),
    offerLink: yup.string().required("Offer link is required"),
  });
  const [file, setFile] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000), // at least +1 day
      key: "selection",
    },
  ]);

  const { setMsg } = useContext(Usercontext);
  const { t } = useTranslation(); // Hook for translation

  //  Setting offer data in the inputs
  useEffect(() => {
    if (SelectedOffer) {
      formik.setValues({
        offerName: SelectedOffer?.name,
        offerLink: SelectedOffer?.Link,
        addStory: false,
      });
      setDateRange([
        {
          startDate: SelectedOffer?.startDate,
          endDate: SelectedOffer?.endDate,
          key: "selection",
        },
      ]);
    }
  }, [SelectedOffer]);


 console.log('SelectedOffer')
 console.log(SelectedOffer)


  const formik = useFormik({
    initialValues: {
      offerName: "",
      offerLink: "",
      addStory: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true)
    setTimeout(() => {
      if (SelectedOffer) { 

        editOffer(SelectedOffer?.id , {name: formik.values?.offerName,
          id:SelectedOffer?.id,
          startDate: dateRange[0].startDate,
          endDate: dateRange[0].endDate,
          Link: formik.values.offerLink,
          img: file && URL.createObjectURL(file[0]),
          lessfor: differenceInDays(dateRange[0].endDate, dateRange[0].startDate),
          IsAstory: true,})
      }else{
        addOffer({
        name: formik.values?.offerName,
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate,
        Link: formik.values.offerLink,
        img: file && URL.createObjectURL(file[0]),
        lessfor: differenceInDays(dateRange[0].endDate, dateRange[0].startDate),
        IsAstory: true,
      });
      }

      
      setIsAddOfferOpen(!isAddOfferOpen);
      formik.setValues();
      setFile(null);
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000), // at least +1 day
          key: "selection",
        },
      ]);
      setSelectedOffer(null);
      if (SelectedOffer) {
        setMsg(["secsses", "your offre Updated successfully"]);
      }else{
        setMsg(["secsses", "your offre added successfully"]);
      }
      setIsLoading(false)
    }, 2000);

    },
  });

  const handleFileDrop = (acceptedFiles) => {
    console.log("Dropped files:", acceptedFiles);
    setFile(acceptedFiles);
  };

  return (
    <>
      {!isAddOfferOpen && (
        <div className="hidden lg:flex flex-col gap-2 items-center">
          <span
            onClick={() => setIsAddOfferOpen(!isAddOfferOpen)}
            className="flex items-center justify-center text-white cursor-pointer h-[3rem] w-[3rem] rounded-[50%] bg-main"
          >
            <AddIcon className="scale-125" />
          </span>
          {t('Offers.AddOffer')}
        </div>
      )}
      {isAddOfferOpen ? (
        <div className="flex flex-col p-5 w-full h-full gap-4 text-left">
          <span className="flex justify-between w-full">
            <h1 className="font-semibold">{t('Offers.AddOffers')}</h1>
            <button
              onClick={() => setIsAddOfferOpen(!isAddOfferOpen)}
              className=""
            >
              <img src={CloseBtn} alt="" />
            </button>
          </span>
          <h1 className="font-semibold text-sm text-gray-600">{t('Offers.OfferPicture')}</h1>
          <div className="w-full flex items-center justify-center">
            <FileDragZone   file={ file|| SelectedOffer?.img   } onFileDrop={handleFileDrop} />
          </div>
          <h1 className="font-semibold text-sm text-gray-600">{t('Offers.OfferName')}</h1>
          <input
            className="w-full h-[3rem] px-2 rounded-md border-[1px]"
            type="text"
            name="offerName"
            value={formik?.values?.offerName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched?.offerName && formik.errors?.offerName && (
            <p className="text-red-500">{formik.errors.offerName}</p>
          )}
          {/* Date Picker */}
          <h1 className="font-semibold text-sm text-gray-600">{t('Offers.OfferPeriod')}</h1>
          <OfferPeriod dateRange={dateRange} setDateRange={setDateRange} />
          <h1 className="font-semibold text-sm text-gray-600">{t('Offers.OfferLink')}</h1>
          <input
            className="w-full h-[3rem] px-2 rounded-md border-[1px]"
            type="text"
            name="offerLink"
            value={formik.values?.offerLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.offerLink && formik.errors?.offerLink && (
            <p className="text-red-500">{formik.errors?.offerLink}</p>
          )}
          <label
            className="cursor-pointer flex flex-row items-center text-gray-500 text-sm gap-2"
            htmlFor="addStory"
          >
            <input
              className="accent-main w-[.9rem] h-[.9rem]"
              type="checkbox"
              name="addStory"
              id="addStory"
              checked={formik?.values?.addStory}
              onChange={formik.handleChange}
            />
           {t('Offers.AddThisOfferAsStory')}
          </label>
          <div className="flex flex-row gap-5">
            <button
              onClick={() => setIsAddOfferOpen(!isAddOfferOpen)}
              className="lg:hidden justify-center flex w-full py-3 text-gray-400 font-semibold bg-gray-200 rounded-md"
            >
               {t('Users.Cancel')}
            </button>
            <button
              type="submit"
              onClick={formik.handleSubmit}
              className="w-full py-3 text-white flex items-center justify-center font-semibold bg-main rounded-md"
            >
              { IsLoading ?
                <Oval
                  visible={IsLoading}
                  height="30"
                  width="30"
                  color="white"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
                :
              'Add'
              }
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setIsAddOfferOpen(!isAddOfferOpen)}
          className="lg:hidden flex fixed bottom-3 right-3 z-30 px-4 py-2 text-white font-semibold rounded-md bg-main"
        >
          Add More
        </button>
      )}
    </>
  );
}

export default AddMoreOffers;
