import React, { useContext, useEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Usercontext } from "../../context/UserContext";
function Massege() {

  const { msg, setMsg } = useContext(Usercontext);
 

  const color = "bg-main text-[#00920F]";

  useEffect(() => {
    // Use setTimeout to hide the message after 2000 milliseconds (2 seconds)
    if (msg) {
      const timeoutId = setTimeout(() => {
        setMsg(false);
      }, 2000);

      // Cleanup the timeout if the component unmounts or if msg changes
      return () => clearTimeout(timeoutId);
    }
  }, [msg]);


  return (
    <div
      className={`  z-50 text-white font-semibold fixed  ${
        msg ? "top-5" : "-top-20"
      }      gap-5  duration-200 ease-out  transform right-[50%] rounded-full translate-x-[50%] pl-4 items-center pr-2 py-2  w-[90%]  lg:w-fit   ${color} `}
    >
      {msg && msg[1]}
      <span  className=" scale-75   right-3  cursor-pointer   top-2   ">
        <CloseOutlinedIcon />
      </span>
    </div>
  );
}

export default Massege;
