import * as Yup from "yup";
export const RegistrationSchema = Yup.object({
  // Define validation rules for each field

  fullName: Yup.string().required("fullName is required"),
  phone: Yup.string().required("phone is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
