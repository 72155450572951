import React, { useEffect, useState } from "react";
import { RegistrationSchema } from "../../../Vlidation/Registration";
import { useFormik } from "formik";
import WestIcon from "@mui/icons-material/West";
import { Link } from "react-router-dom";

function StepTwo({ setStep }) {
  const formik = useFormik({
    initialValues: {
      CompanyName: "",
      Country: "",
      PostalCode: "",
    },
    validationSchema: RegistrationSchema,
    onSubmit: (values) => {
      console.log("Form submitted with values:", values);
    },
  });

  const renderInput = (name, label, type = "text") => (
    <div className="mb-4 flex flex-col gap-2 ">
      <label htmlFor={name} className="block text-sm font-medium text-gray-500">
        {label}
      </label>
      <div>
        <input
          type={type}
          id={name}
          name={name}
          onChange={() => formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          className="w-full h-[3.5rem] px-2 border-solid border-2 rounded-lg"
        />

        {formik.touched[name] && formik.errors[name] && (
          <div className="text-red-500 text-sm mt-1">{formik.errors[name]}</div>
        )}
      </div>
    </div>
  );

  return (
    <form
      className=" flex flex-col  lg:justify-normal justify-between h-full"
      onSubmit={formik.handleSubmit}
    >
      <div>
        {renderInput("CompanyName", "Company name")}
        {renderInput("Country", "Country")}
        {renderInput("PostalCode", "Zip / Postal code")}
        <div className=" flex gap-3 items-center my-6 text-gray-400 ">
          <input
            className="  accent-main h-[1.2rem] w-[1.2rem]  "
            type="checkbox"
            name="Terms"
            id="Terms"
          />
          <label className=" cursor-pointer" htmlFor="Terms">
            I agree to the Fintechracy{" "}
            <span className=" text-main">Terms and Conditions</span>{" "}
          </label>
        </div>
      </div>
      <div className="  lg:relative  absolute lg:bottom-0 bottom-10   transform translate-x-[50%] right-[50%] w-full flex flex-row gap-6 mt-2 ">
        <button
          onClick={() => setStep(0)}
          className=" w-full border-solid py-4  border-2 text-main font-bold border-main rounded-lg  "
        >
          Go Back
        </button>
        <button
          type="submit"
          className="  w-full bg-main text-lg  text-white py-4 font-semibold  px-4 rounded-lg"
        >
          Sign up
        </button>
      </div>
    </form>
  );
}

export default StepTwo;
