import React from "react";
import Modal from "../../Components/UI/Modal";
import closeBtn from "../../assest/images/CloseBtn.svg";
import { useTranslation } from "react-i18next";
function ChangeEmail({ isOpen, setIsOpen }) {
  const {t} = useTranslation()

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="  w-[85vw] lg:w-[40vw]   px-1 pt-3  gap-3 flex flex-col ">
        <img
          onClick={() => setIsOpen(false)}
          className=" cursor-pointer absolute top-4 right-4"
          src={closeBtn}
          alt=""
        />
        <h1 className="  text-base lg:text-xl font-semibold">{t('Profile.BusinessEmail')}</h1>
        <span>
          <p className=" text-gray-500   text-base lg:text-xl font-semibold">
          {t('Profile.WriteYourNewBusinessEmail')}
          </p>
          <p className=" text-xs  lg:text-sm text-gray-400">
          {t('Profile.PleaseEnterYourNewBusinessEmailAddressBelow')}
          </p>
        </span>

        <div className=" flex  flex-col lg:flex-row gap-3">
          <input
            className=" px-3 w-full  h-[3rem] border-[1px] border-solid rounded-md "
            type="email"
            placeholder={t('Profile.WriteYourEmailHere')}
          />
          <button className="  h-[3rem]   px-4  bg-main rounded-md text-white  font-semibold text-lg ">
          {t('Profile.Submit')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeEmail;
