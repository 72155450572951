import React, { useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import GrayLine from "../Components/UI/GrayLine";
import ContactImage from '../assest/images/contact_support.svg'
import { useTranslation } from "react-i18next";
function ContactSupport() {
  const [Option, setOption] = useState("technicalSupport");
  const [OptionIsOpen, setOptionIsOpen] = useState(false);
  const {t} =  useTranslation(); 
  return (
    <div className=" w-full  h-[100vh] flex flex-col items-center  lg:pt-0   bg-white ">
     <img src={ContactImage} className="  py-10 lg:py-20 " alt="" />
      <span className=" flex flex-col text-sm  lg:text-[17px]  leading-[26px]    text-gray-500 w-full px-[1rem]  lg:w-[50%] gap-20 ">
        <p>
           {t('contact.ThankYouForReaching')}
        </p>

        <div className=" flex flex-col gap-10 items-center ">
          {/* button Mobile View */}
          <span
            onClick={() => setOptionIsOpen(!OptionIsOpen)}
            className=" lg:hidden  relative flex flex-row px-4 py-2 border-[1px]   font-medium border-main rounded-md gap-2  "
          >
            {Option != "administrativeSupport"
              ? t('contact.TechnicalSupport')
              :  t('contact.AdministrativeSupport')}
            <KeyboardArrowDownOutlinedIcon className=" text-main scale-90" />
            {OptionIsOpen && (
              <span className=" justify-between gap-2 shadow-md absolute top-12 right-0 w-full  py-2 bg-white flex flex-col  ">
                <h1
                  className={` ${
                    Option != "administrativeSupport"
                      ? "   text-main "
                      : ""
                  } `}
                  onClick={() => setOption("technicalSupport")}
                >
                 {t('contact.TechnicalSupport')}
                </h1>
                <GrayLine />
                <h1  className={` ${
                    Option == "administrativeSupport"
                      ? "   text-main "
                      : ""
                  } `} onClick={() => setOption("administrativeSupport")}>
                  {t('contact.AdministrativeSupport')}
                </h1>
              </span>
            )}
          </span>

          <span className="  hidden lg:flex  text-white  ">
            <button
              onClick={() => setOption("technicalSupport")}
              className={` ${
                Option == "technicalSupport"
                  ? "bg-main text-white "
                  : "text-gray-500"
              } text-lg font-semibold w-full h-[4rem]  px-5  rounded-t-[2rem]`}
            >
             {t('contact.TechnicalSupport')}
            </button>
            <button
              onClick={() => setOption("administrativeSupport")}
              className={` ${
                Option == "administrativeSupport"
                  ? "bg-main text-white "
                  : "  text-gray-500"
              } text-lg font-semibold w-full h-[4rem]  px-5 rounded-t-[2rem]`}
            >
            {t('contact.AdministrativeSupport')}
            </button>
          </span>

          {Option == "technicalSupport" ? (
            <span className=" gap-3 flex flex-col">
              <p className="  text-sm  lg:text-[17px] leading-[26px]  text-gray-500 ">
                {t('contact.ForIssuesRelatedToOurPlatformsFunctionalityAccountTroubleshootingOrAnyTechnicalInquiries')}
              </p>
              <a href="mailto:tech.support@fintechracy.com" class="text-main underline">tech.support@fintechracy.com</a>
            
            </span>
          ) : (
            <span className=" gap-3 flex flex-col">
              <p className="   text-sm  lg:text-[17px] leading-[26px]  text-gray-500 ">
              {t('contact.IfYouHaveQuestionsRegardingBusinessOperationsAccountManagementOrAnyAdministrativeMatters')}
              </p>
              <a href="mailto:business.support@fintechracy.com" class="text-main underline">business.support@fintechracy.com</a>
            </span>
          )}
        </div>
      </span>
    </div>
  );
}

export default ContactSupport;
