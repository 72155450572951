import React from "react";
import { useDropzone } from "react-dropzone";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
const FileDragZone = ({ onFileDrop , file , profile }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileDrop,
  });

  return (
    
    <div className=" w-full h-full cursor-pointer ">
  
    <div
      {...getRootProps()}
      className={ ` w-full h-full relative border-dashed border-[3px] ${profile ? ' bg-white':'bg-gray-50' }  rounded-md  ${
        isDragActive ? "dragging" : ""
      }`}
    >
         {file &&  !profile && <img src={typeof file === 'string' ? file : (file && URL.createObjectURL(file[0]))} className=" absolute top-0 right-0 z-30 object-cover w-full h-full" alt="" />}
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drag and drop files here</p>
      ) : (
        <div className=" flex text-gray-400 flex-col items-center w-full h-full justify-center gap-10 py-10">
            <ImageOutlinedIcon className= {` ${profile ?' scale-[2.3] ' :'scale-[3.4]'}   `}/>
            <h1 className=" font-semibold text-center text-sm">Drop your file here or <span className=" text-main">Browse</span> </h1>
        </div>
      )}
    </div>
    </div>
  );
};

export default FileDragZone;
