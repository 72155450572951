import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from "../../assest/images/logoW.png";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LinksComponent from '../../assest/data/Links'
import { useTranslation } from 'react-i18next';
function SidBar({setIsSidBarOpen, isSidBarOpen}) {

  const { pathname } = useLocation();
  const links = LinksComponent();
  const { t, i18n } = useTranslation();
  
const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const languages = [
    {
      text: "English",
      value: "en",
      // img: EnFlag,
    },
    {
      text: "Français",
      value: "fr",
      // img: FrFlag,
    },
  ];

  return (
    <>
    <div className=" pt-10  sticky top-0   hidden  lg:flex flex-col justify-between  lg:pt-6  pb-10 font-semibold text-gray-500 px-6  w-[25%]  xl:w-[22%] h-[100vh]  bg-white  ">
    <div className="  flex text-left flex-col gap-[2rem] ">
      <span className=" px-3 items-start h-[3.5rem] w-full flex  ">
        {" "}
        <img
          className="w-[150px] h-[35px]   "
          src={Logo}
          alt=""
        />
      </span>
      <div className=" flex flex-col gap-2 ">
        {links.map((link) => (
          <Link
            className={` ${
              pathname == link.link ? " bg-main text-white" : "  "
            } rounded-md   w-full gap-3 h-[3rem] flex items-center px-3  `}
            to={link.link}
          >
            <img
              src={pathname == link.link ? link.active : link.img}
              alt=""
            />
            {link.text}{" "}
          </Link>
        ))}
      {/* switch lang */}
      <div className=" px-4 flex flex-col gap-2 w-full  pt-[2rem] ">
          {languages?.map((item) => (
            <span
              onClick={() => changeLanguage(item.value)}
              className={` cursor-pointer ${
                i18n.language.startsWith(item.value)
                  ? `    font-medium `
                  : " "
              }  flex w-full items-center gap-2`}
            >
              {/* <img className=" h-[3.4rem] w-[3.4rem] " src={item.img} /> */}
              <h1> {item.text} </h1>
            </span>
          ))}
        </div>
      </div>
    </div>
    <button className=" text-left">
      <LogoutOutlinedIcon className=" scale-90" />  {t('SidBar.LogOut')}
    </button>
  </div>
  
    {/* Mobile view Side bar */}
    <div
        className={` z-40 lg:hidden  fixed  text-left ${
          isSidBarOpen ? "left-0" : " left-[-100%] "
        }  duration-200 top-0 border-r-[1px]  w-full flex flex-col justify-between py-10 font-semibold text-gray-500 px-6   h-[100vh]   `}
      >
        <span
          onClick={() => setIsSidBarOpen(!isSidBarOpen)}
          className=" w-full h-full absolute top-0 right-0 bg-black opacity-25 "
        ></span>

        <div
          className={`lg:hidden   absolute  text-left ${
            isSidBarOpen ? "left-0" : " left-[-100%] "
          }  duration-200 top-0 border-r-[1px]  w-[70%] flex flex-col justify-between py-9 font-semibold text-gray-500 px-6   h-[100vh]  bg-white`}
        >
          <div className=" flex text-left flex-col ">
            <span className=" h-[3.5rem] ">
            <img
              className=" mx-3 w-[150px] h-[35px]   "
              src={Logo}
              alt=""
            />
            </span>
            <div className=" flex flex-col gap-2 ">
              {links.map((link) => (
                <Link
                  onClick={() => setIsSidBarOpen(!isSidBarOpen)}
                  className={` ${
                    pathname == link.link ? " bg-main text-white" : "  "
                  } rounded-md   w-full gap-3 h-[3rem] flex items-center px-3`}
                  to={link.link}
                >
                  <img
                    src={pathname == link.link ? link.active : link.img}
                    alt=""
                  />
                  {link.text}
                </Link>
              ))}
            </div>
                      {/* switch lang */}
      <div className=" px-4 flex flex-col gap-2 w-full  pt-[2rem] ">
          {languages?.map((item) => (
            <span
              onClick={() => changeLanguage(item.value)}
              className={` cursor-pointer ${
                i18n.language.startsWith(item.value)
                  ? `    font-medium `
                  : " "
              }  flex w-full items-center gap-2`}
            >
              {/* <img className=" h-[3.4rem] w-[3.4rem] " src={item.img} /> */}
              <h1> {item.text} </h1>
            </span>
          ))}
        </div>
          </div>
          <button className=" text-left">
          <LogoutOutlinedIcon className=" scale-90" /> Logout
        </button>
        </div>

      </div>
  
  </>
  )
}

export default SidBar