import React, { useEffect, useState } from "react";
import { RegistrationSchema } from "../../../Vlidation/Registration";
import { useFormik } from "formik";
import WestIcon from "@mui/icons-material/West";
import { Link } from "react-router-dom";

function StepOne({ setStep }) {
  const [progress, setProgress] = useState(0);


  useEffect(() => {
    const storedFormValues = localStorage.getItem("StepOneValues");

    if (storedFormValues) {
      const parsedFormValues = JSON.parse(storedFormValues);
      formik.setValues(parsedFormValues);
    }
  }, []); 

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: RegistrationSchema,
    onSubmit: (values) => {
      console.log("Form submitted with values:", values);
      localStorage.setItem("StepOneValues", JSON.stringify(values));
      setStep(1);

    },
  });

  const calculateStrength = (password) => {
    // Your password strength calculation logic here
    // You can use libraries like zxcvbn for a more advanced strength calculation
    // For simplicity, let's assume the password length determines the strength
    const strength = Math.min((password.length / 8) * 100, 100); // Adjust the formula as needed

    setProgress(strength);
  };

  useEffect(() => {
    calculateStrength(formik?.values?.password);
  }, [formik?.values?.password]);

  const renderInput = (name, label, type = "text") => (
    <div className="mb-4 flex flex-col gap-2 ">
      <label htmlFor={name} className="block text-sm font-medium text-gray-500">
        {label}
      </label>
      <div>
        <input
          type={type}
          id={name}
          name={name}
          onChange={(e) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          className="w-full h-[3.5rem] px-2 border-solid border-2 rounded-lg"
        />
        {name == "password" && (
          <div className=" flex flex-col gap-2 ">
            <div className=" w-full h-[.8rem] rounded-full bg-slate-100  relative mt-5  ">
              <span
                className=" bg-main h-full absolute top-0 left-0 duration-150 rounded-full  "
                style={{
                  width: `${progress}%`,
                }}
              ></span>
            </div>
            <p className="   text-sm text-gray-500">
              Your password is too weak
            </p>
          </div>
        )}
        {formik.touched[name] && formik.errors[name] && (
          <div className="text-red-500 text-sm mt-1">{formik.errors[name]}</div>
        )}
      </div>
    </div>
  );

  return (
    <form className="" onSubmit={formik.handleSubmit}>
      {renderInput("fullName", "Full name")}
      {renderInput("phone", "Phone number")}
      {renderInput("email", "Bussiness Email ")}
      {renderInput("password", "Password", "password")}
      {renderInput("confirmPassword", "Confirm Password", "password")}

      <button
        type="submit"
        className="  w-full bg-main text-lg mt-2 text-white py-4 font-semibold  px-4 rounded-lg"
      >
        Next
      </button>
    </form>
  );
}

export default StepOne;
