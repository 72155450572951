// LinksComponent.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferImages } from '../../assest/images/sidBar/ImgSidbarData.js';

const LinksComponent = () => {
  const { t } = useTranslation(); // Hook for translation

  const links = [
    {
      text: t('SidBar.GetStarted'), // Translate text using t() function
      link: '/getStarted',
      img: OfferImages.get_started,
      active: OfferImages.get_startedSlected,
    },
    {
      text: t('SidBar.Dashboard'),
      link: '/dashboard',
      img: OfferImages.dashboard,
      active: OfferImages.dashboardSlected,
    },
    {
      text: t('SidBar.Offers'),
      link: '/offers',
      img: OfferImages.offers,
      active: OfferImages.offersSlected,
    },
    {
      text: t('SidBar.Users'),
      link: '/',
      img: OfferImages.users,
      active: OfferImages.users_1Slected,
    },
    {
      text: t('SidBar.Profile'),
      link: '/profile',
      img: OfferImages.profile,
      active: OfferImages.profileSlected,
    },
    {
      text: t('SidBar.ContactSupport'),
      link: '/contactSupport',
      img: OfferImages.contact,
      active: OfferImages.contactSlected,
    },
  ];

  return links;
};

export default LinksComponent;
