import {React , useState , useEffect } from "react";
import Modal from "../UI/Modal";
import deleteIcon from "../../assest/images/delete_user.svg";
function EditUser({ Edit, setEdit , Onclick , id , UserRole   }) {

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState( [UserRole]);
  
   console.log(selectedRoles)
  
    const handleRoleSelection = (selectedRole) => {
      const roleMappings = {
        SuperAdmin: ['SuperAdmin', 'Admin', 'User', 'Manage Dashboard', 'Manage Offers', 'Manage Users', 'View Dashboard', 'View Offers', 'View Users'],
        Admin: ['Admin', 'Manage Dashboard', 'Manage Offers', 'Manage Users'],
        User: ['User', 'View Dashboard', 'View Offers', 'View Users'],
      };
  
      if (roleMappings[selectedRole]) {
        const rolesToAdd = roleMappings[selectedRole];
  
        if (rolesToAdd.every(role => selectedRoles.includes(role))) {
          setSelectedRoles(selectedRoles?.filter(role => !rolesToAdd.includes(role)));
        } else {
          setSelectedRoles([...selectedRoles?.filter(role => !rolesToAdd.includes(role)), ...rolesToAdd]);
        }
      } else {
        const updatedRoles = selectedRoles.includes(selectedRole)
          ? selectedRoles?.filter(role => role !== selectedRole && role !== 'SuperAdmin'  )
          : [...selectedRoles, selectedRole];
  
        setSelectedRoles(updatedRoles);
      }
    };
  useEffect(() => {
      const superAdminSubRoles = ['Admin', 'User', 'Manage Dashboard', 'Manage Offers', 'Manage Users', 'View Dashboard', 'View Offers', 'View Users'];
      
      // Check if all sub-roles of SuperAdmin are selected
      const areSuperAdminSubRolesSelected = superAdminSubRoles.every(subRole => selectedRoles.includes(subRole));
  
      // If all sub-roles are selected and SuperAdmin is not in the array, add it
      if (areSuperAdminSubRolesSelected && !selectedRoles.includes('SuperAdmin')) {
        setSelectedRoles([...selectedRoles, 'SuperAdmin']);
      }
      // If any of the sub-roles is not selected and SuperAdmin is in the array, remove it
      else if (!areSuperAdminSubRolesSelected && selectedRoles.includes('SuperAdmin')) {
        setSelectedRoles(selectedRoles?.filter(role => role !== 'SuperAdmin'));
      }
    }, [selectedRoles]); // Run the effect whenever selectedRoles change
  

    console.log(selectedItem)

    const Roles = [
      "SuperAdmin",
      {
        Admin: ["Manage Dashboard", "Manage Offers", "Manage Users"],
      },
      {
        User: ["View Dashboard", "View Offers", "View Users"],
      },
    ];




  return (
    <Modal
      isOpen={Edit}
   
      onClose={() => setEdit(!Edit)}
    >
      <div className="  w-[60vw]   px-3 lg:px-6 flex lg:w-full p-2 justify-between flex-col gap-3">
        <h1 className="    font-medium text-lg">Modify this user’s role</h1>
                  <div className="  text-gray-700  flex flex-col   gap-2 lg:gap-4    ">
                    {Roles.map((role, index) => (
                      <span
                        className="  flex"
                        key={index}
                      >
                        {typeof role === "object" ? (
                          <div className="  gap-2 lg:gap-4  flex flex-col">
                            {Object.keys(role).map((subRole) => (
                              <div key={subRole}>
                                <span className="  font-medium w-full justify-start items-center gap-2 flex  " >
                                  <input
                                    className=" h-[1rem] w-[1rem] accent-main"
                                    type="checkbox"
                                    name={subRole}
                                    checked={selectedRoles.includes(subRole)}
                                    onChange={() => handleRoleSelection(subRole)}
                                    id={subRole}
                                  />
                                  {subRole}
                                </span>
                                <span className="     w-full px-3 grid-cols-1  pt-4  gap-2 lg:gap-4  items-start justify-end flex text-sm font-normal   flex-col">
                                  {role[subRole].map(
                                    (subRoleItem, subIndex) => (
                                      <span className=" flex items-center gap-2" key={subIndex}>
                                        <input
                                          className=" h-[1rem] w-[1rem] accent-main"
                                          type="checkbox"
                                          name={subRoleItem}
                                          checked={
                                            
                                            selectedRoles.includes(subRoleItem)
                                          }
                                          onChange={() =>
                                            handleRoleSelection(subRoleItem)
                                          }
                                          id={subRoleItem}
                                        />{" "}
                                        {subRoleItem}
                                      </span>
                                    )
                                  )}
                                </span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <span className=" flex  items-center gap-2 font-medium ">
                            <input
                              className=" h-[1rem] w-[1rem]  "
                              type="checkbox"
                              name={role}
                              onChange={() =>handleRoleSelection(role)}
                              checked={selectedRoles.includes(role)}
                              id={role}
                            />
                            {role}
                          </span>
                        )}
                      </span>
                    ))}
                  </div>
                
        <div onClick={() => setEdit(!Edit)} className=" flex  pt-2 flex-col gap-4 w-full  font-semibold justify-between">
            <button onClick={ ()=> Onclick(id , selectedItem) } className=" p-2  w-full rounded-md bg-main   text-white  ">Confirm</button>
            <button className=" p-2 w-full rounded-md  bg-gray-300 text-gray-500  ">Cancel</button>
        </div>
      </div>
    </Modal>
  );
}

export default EditUser;
