import React, { useEffect, useState } from "react";
// import { RegistrationSchema } from "../../../Vlidation/Registration";
import { useFormik } from "formik";
import WestIcon from "@mui/icons-material/West";
import { Link } from "react-router-dom";

function ResetPassword({  }) {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    // validationSchema: ,
    onSubmit: (values) => {
      console.log("Form submitted with values:", values);
    },
  });

  const renderInput = (name, label, type = "text") => (
    <div className="mb-4 flex flex-col gap-2 ">
      <label htmlFor={name} className="block  lg:text-base text-sm font-medium text-gray-500">
        {label}
      </label>
      <div>
        <input
          type={type}
          id={name}
          name={name}
          onChange={(e) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          className="w-full h-[3.5rem] px-2 border-solid border-2 rounded-lg"
        />
        {formik.touched[name] && formik.errors[name] && (
          <div className="text-red-500 text-sm mt-1">{formik.errors[name]}</div>
        )}
      </div>
    </div>
  );

  return (
    <div className=" justify-center  relative  w-full  px-[1px]  md:px-[7%] flex h-[100vh]   text-start flex-col   pt-12  lg:pt-0 ">
      <div className=" flex h-full flex-col   justify-between ">
        <span className=" absolute top-3 flex lg:hidden  left-0">
          <WestIcon />
        </span>
        <div className=" flex flex-col text-center  lg:text-left ">
          <h1 className=" text-2xl font-semibold ">Reset Password</h1>
          <p className=" pt-4 font-semibold pb-10 text-gray-400 ">
          Enter your email to send you the verification code
          </p>
        </div>
        <form className="   h-[70%] flex flex-col  lg:justify-start justify-between  " onSubmit={formik.handleSubmit}>
          <div className="   flex flex-col  justify-center">
          {renderInput("email", "Bussiness Email ")}
          </div>
         <div className=" h-full flex flex-col  justify-end lg:justify-between ">
             <button
            type="submit"
            className="  w-full bg-main text-lg mt-2 text-white py-4 font-semibold  px-4 rounded-lg"
          >
           Send code
          </button>
          <div className=" w-full items-center justify-center pt-3 text-gray-400 font-semibold flex flex-row gap-1 ">
            <p>Remember your password  ?</p>
            <Link to={"/Auth"} className=" text-main">
              Sign in
            </Link>
          </div>
         </div>
       
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
