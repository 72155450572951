import offer from "../assest/images/offer.jpg";
import offer01 from "../assest/images/offer01.jpg";
import offer02 from "../assest/images/offer02.jpg";
import offer03 from "../assest/images/offer03.jpg";
import offer04 from "../assest/images/offer04.jpg";

export const OffersData = [
  {
    id: 1,
    name: "Nike",
    startDate: "2024-01-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },

  {
    id: 2,
    name: "Nike",
    img: offer02,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 6,
    IsAstory: true,
  },
  {
    id: 3,
    name: "Nike",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 4,
    name: "Nike",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 5,
    name: "adidas",
    img: offer03,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 8,
    IsAstory: true,
  },
  {
    id: 6,
    name: "adidas",
    img: offer04,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 7,
    IsAstory: true,
  },
  {
    id: 7,
    name: "adidas",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 8,
    name: "Nike",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 9,
    name: "adidas",
    img: offer03,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 8,
    IsAstory: true,
  },
  {
    id: 10,
    name: "adidas",
    img: offer04,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 7,
    IsAstory: true,
  },
  {
    id: 11,
    name: "adidas",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 12,
    name: "Nike",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 13,
    name: "adidas",
    img: offer03,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 8,
    IsAstory: true,
  },
  {
    id: 14,
    name: "adidas",
    img: offer04,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 7,
    IsAstory: true,
  },
  {
    id: 15,
    name: "adidas",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 16,
    name: "Nike",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 17,
    name: "adidas",
    img: offer03,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 8,
    IsAstory: true,
  },
  {
    id: 18,
    name: "adidas",
    img: offer04,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 7,
    IsAstory: true,
  },
  {
    id: 19,
    name: "adidas",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 20,
    name: "Nike",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 21,
    name: "Nike",
    img: offer02,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 6,
    IsAstory: true,
  },
  {
    id: 22,
    name: "adidas",
    img: offer03,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 8,
    IsAstory: true,
  },
  {
    id: 23,
    name: "adidas",
    img: offer04,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 7,
    IsAstory: true,
  },
  {
    id: 24,
    name: "adidas",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 25,
    name: "Nike",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 26,
    name: "Nike",
    img: offer02,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 6,
    IsAstory: true,
  },
  {
    id: 27,
    name: "adidas",
    img: offer03,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 8,
    IsAstory: true,
  },
  {
    id: 28,
    name: "adidas",
    img: offer04,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 7,
    IsAstory: true,
  },
  {
    id: 29,
    name: "adidas",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 30,
    name: "Nike",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer01,
    lessfor: 3,
    IsAstory: true,
  },
  {
    id: 31,
    name: "Nike",
    img: offer02,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 6,
    IsAstory: true,
  },
  {
    id: 32,
    name: "adidas",
    img: offer03,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 8,
    IsAstory: true,
  },
  {
    id: 33,
    name: "adidas",
    img: offer04,
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    lessfor: 7,
    IsAstory: true,
  },
  {
    id: 34,
    name: "adidas",
    startDate: "2023-11-30T21:11:51.408534Z",
    endDate: "2023-11-30T21:11:51.408534Z",
    Link: "www.offer.com",
    img: offer,
    lessfor: 3,
    IsAstory: true,
  },
];
