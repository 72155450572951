// Importing necessary modules and dependencies
import { createContext, useEffect, useState } from "react";
import userImg from '../assest/images/userImg.jpg'
export const Usercontext = createContext();

// Userprovider Component
const Userprovider = ({ children }) => {

const [msg, setMsg] = useState(null);

 const [User , setUser ] = useState({
  name:'Chaouki GueddouGueddouGueddou',
  image: userImg,
  company:'Fintechracy',
  contry:'Algeria',
  codePostal:16000,
  businessemail:'ChaoukiGueddou@Fintechracy.com',
  capabilities:[
    'Manage offers',
    'Manage Users',
    'View offers',
  ]
 })



const [Users, setUsers] = useState([
  {
    id:1,
    name: "Chaouki Gueddouuuuuuooooooo",
    email: "Chaouki.gueddougueddougueddou@fintechracy.com",
    phone: "+2984448984",
    role: "Super Admin",
  },
  {
    id:2,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "Admin",
  },
  {
    id:3,
    name: "Aziz ",
    email: "Aziz@fintechracy.com",
    phone: "+2984448984",
    role: "User",
  },
  {
    id:4,
    name: "Aicha",
    email: "Aicha@fintechracy.com",
    phone: "+2984448984",
    role: "Super Admin",
  },
  {
    id:5,
    name: "Aziz ",
    email: "Aziz@fintechracy.com",
    phone: "+2984448984",
    role: "User",
  },
  {
    id:6,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },

  {
    id:7,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "Admin",
  },
  {
    id:8,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:9,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:10,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:11,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:12,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:13,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:14,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:15,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:16,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
  {
    id:17,
    name: "Mohamed ",
    email: "Mohamed@fintechracy.com",
    phone: "+2984988984",
    role: "User",
  },
] 
 );


  return (
    <Usercontext.Provider value={{ msg, setMsg ,  User , setUser , setUsers, Users }}>
      {children}
    </Usercontext.Provider>
  );
};

// Exporting the Userprovider component
export default Userprovider;
