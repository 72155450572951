import React, { useEffect, useState , useRef } from "react";


// import { RegistrationSchema } from "../../../Vlidation/Registration";
import { useFormik } from "formik";
import WestIcon from "@mui/icons-material/West";
import { Link } from "react-router-dom";

function VerificationCode() {
    const [code, setCode] = useState(["", "", "", "", "", ""]);
    const inputRefs = [
      useRef(),
      useRef(),
      useRef(),
      useRef(),
      useRef(),
      useRef(),
    ];
    const handleChange = (index, e) => {
        const value = e.target.value;
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);
    
        if (value && index < 5) {
          inputRefs[index + 1].current.focus();
        }
      };  
   
  return (
    <div className=" justify-center  relative  w-full  px-[1px]  md:px-[7%] flex h-[100vh]   text-start flex-col   pt-12  lg:pt-0 ">
      <div className=" flex h-full flex-col   justify-between ">
        <span className=" absolute top-3 flex lg:hidden  left-0">
          <WestIcon />
        </span>
        <div className=" flex flex-col text-center  lg:text-left ">
          <h1 className=" text-2xl font-semibold ">Verification code</h1>
          <p className=" pt-4 font-semibold pb-10 text-gray-400 ">
          verification code has been sent to a*******06@gmail.com
          </p>
        </div>
        <form className="   h-[70%] flex flex-col  lg:justify-start justify-between  " >
        <div className=" flex flex-row  gap-7 ">
              {code.map((digit, index) => (
                <input
                  key={index}
                  ref={inputRefs[index]}
                  type="text"
                  value={digit}
                  className={`   text-center  bg-transparent border-sec border-opacity-25  text-[1.8rem] w-[50%] h-[3.8rem]   px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
                  onChange={(e) => handleChange(index, e)}
                  maxLength={1}
                />
              ))}
            </div>
         <div className=" h-full flex flex-col  justify-end lg:justify-between ">
             <button
            type="submit"
            className=" mt-4  w-full bg-main text-lg mt-2 text-white py-4 font-semibold  px-4 rounded-lg"
          >
           Verify 
          </button>
          <div className=" w-full items-center justify-center pt-3 text-gray-400 font-semibold flex flex-row gap-1 ">
            <p>Remember your password ?</p>
            <Link to={"/Auth"} className=" text-main">
              Sign in
            </Link>
          </div>
         </div>
       
        </form>
      </div>
    </div>
  );
}

export default VerificationCode;
