import React, { useContext, useEffect, useState } from "react";
import AddUsers from "../Components/Users/AddUsers";
import UsersList from "../Components/Users/UsersList";
import { Usercontext } from "../context/UserContext";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { handleSearch } from "../utils/Functions/Search";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
function Users() {
  const { Users } = useContext(Usercontext);
  const [IsAddOpen, setIsAddOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [FilteredUsers, setFilteredUsers] = useState(Users);
  const { t } = useTranslation(); // Hook for translation


 useEffect(()=>{
  setFilteredUsers(Users)
 },[Users] )

  const itemsPerPage = 12; // Adjust the number of items to display per page

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = FilteredUsers?.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(FilteredUsers?.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const searchKeysForReceipts = [
    "name",
    "role",
    "email",
    "phone",
  ];

  const handelSearchInput = (value) => {
    handleSearch(Users, value, setFilteredUsers, searchKeysForReceipts);
  };

  return (
    <>
    {/* Search */}
      <span className=" w-full    rounded-md  justify-between  flex    md:px-[2rem]  px-[1rem]  md:mb-3 bg-white py-4  relative">
        <SearchIcon className=" absolute top-[1.9rem] scale-[.8] left-6  md:left-10 text-gray-400 " />
        <input
          onChange={(e) => handelSearchInput(e.target.value)}
          placeholder={t('Offers.SearchHere')} 
          className="px-9 flex h-[3.2rem]  md:w-[40%] w-full border-solid rounded-md border-[1px]"
        />
        <button
          onClick={() => setIsAddOpen(true)}
          className=" font-semibold lg:relative fixed  z-20 lg:bottom-0 bottom-5 right-5  text-white bg-main  px-6 p-2 rounded-md "
        >
        <AddIcon/>  {t('Users.Invite')} 
        </button>
      </span>


      <div className=" flex flex-col  rounded-md w-full h-full bg-white  lg:px-0 px-4 pt-2 ">
        <span className=" hidden  lg:grid grid-cols-5  border-b-2 pb-3   text-left   w-full px-8">
          <h1> {t('Users.User')} </h1>
          <h1>Email</h1>
          <h1 className="pl-16">{t('Users.PhoneNumber')} </h1>
          <h1 className="pl-10">{t('Users.Roles')}  </h1>
          <h1 className=" text-center">{t('Users.Actions')}  </h1>
        </span>
        {/* the users List */}
        {FilteredUsers?.map((item) => (
          <UsersList
            id={item.id}
            role={item.role}
            email={item.email}
            phone={item.phone}
            name={item.name}
          />
        ))}
        <AddUsers  setIsAddOpen={setIsAddOpen} IsAddOpen={IsAddOpen}  />
        <div className=" py-10 text-main">
          <button
            className={` ${
              currentPage === 1 ? " opacity-25 " : " opacity-100"
            } `}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <NavigateNextOutlinedIcon className=" rotate-180" />
          </button>
          <span className=" text-gray-800"> {currentPage} </span>
          <button
            className={` ${
              currentPage === totalPages ? " opacity-25 " : " opacity-100"
            } `}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <NavigateNextOutlinedIcon />
          </button>
        </div>
      </div>
    </>
  );
}

export default Users;
