import React, { useState } from "react";
import CloseBtn from "../../assest/images/CloseBtn.svg";
import offer from "../../assest/images/offer.jpg";
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";
function OfferDeteils({
  id,
  name,
  img,
  endDate,
  startDate,
  Link,
  IsAstory,
  setShowDetails,
}) {
  const { t } = useTranslation(); // Hook for translation

  if(!endDate || !startDate )return

  return (
    <div className=" relative w-full  lg:h-full  h-[80vh] p-3  px-6 justify-between flex flex-col ">
      <div className=" text-left  flex flex-col gap-2 items-center w-full">
        <span className=" flex  justify-between w-full ">
          <h1 className=" font-semibold">Add offers</h1>
          <button onClick={() => setShowDetails(null)} className=" ">
            <img src={CloseBtn} alt="" />
          </button>
        </span>

        <h1 className=" w-full text-sm font-semibold">{t('Offers.DeleteOffer')}</h1>
        <img
          className=" w-full h-[15rem] object-cover rounded-md "
          src={img}
          alt=""
        />
        <div className=" pt-7 flex flex-col gap-2 w-full">
          <span className=" flex justify-between w-full">
            <h1 className=" text-sm font-semibold">{t('Offers.OfferName')}</h1>
            {name}
          </span>

          <span className=" flex justify-between text-sm  w-full">
            <h1 className=" text-sm font-semibold">{t('Offers.OfferPeriod')}</h1>
            {format(startDate, 'MM/dd/yyyy')} - {format(endDate, 'MM/dd/yyyy')}
          </span>
          <span className=" flex justify-between w-full">
            <h1 className=" text-sm font-semibold">{t('Offers.OfferLink')}</h1>
            {Link}
          </span>
          <span className=" flex justify-between w-full">
            {IsAstory && (
              <h1 className=" text-sm font-semibold">
                {t('Offers.ThisOfferAddedAsStory')}
              </h1>
            )}
          </span>
        </div>
      </div>
      <div className=" items-center justify-center flex lg:hidden text-gray-500  bottom-3 " onClick={() => setShowDetails(null)} >Back to offers page </div>
    </div>
  );
}

export default OfferDeteils;
