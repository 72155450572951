import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Index from './Pages/Index.jsx'
import NotFound from './Pages/NotFound.jsx'
import Auth from './Pages/Auth.jsx';
import Massege from './Components/UI/Massege.jsx';

import { useContext } from 'react';
import { Usercontext } from './context/UserContext.js';
function App() {
  

  return (
    <div className="App   ">
      <Massege/>
       <Router>
         <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/:sub" element={<Index />} />
          <Route path="/Auth" element={<Auth />} />
          <Route path="/Auth/:sub" element={<Auth />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
    </div>
  );
}

export default App;
