import {React , useState } from "react";
import {  DateRange } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
function OfferPeriod({dateRange, setDateRange}) {
  
      const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
      };

  return (
    <div>
      <DateRange
            className=" lg:scale-100 scale-110  lg:mt-0  mt-10 w-full"
            ranges={dateRange}
            color="#06c671"
            ariaLabels={false}
            rangeColors={"#06c671"}
            onChange={handleSelect}
            months={1}
          />
    </div>
  );
}

export default OfferPeriod;
