import { React, useContext, useEffect, useState } from "react";
import closeBtn from "../../assest/images/CloseBtn.svg";
import Modal from "../../Components/UI/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Usercontext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
const schema = z.object({
  email: z.string().email({ message: "Invalid email format" }),
});

function AddUsers({ setIsAddOpen, IsAddOpen }) {
  const [isRole, setIsRole] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { setUsers , Users } = useContext(Usercontext);

  const { t } = useTranslation(); // Hook for translation

  const handleRoleSelection = (selectedRole) => {
    const roleMappings = {
      SuperAdmin: ['SuperAdmin', 'Admin', 'User', 'Manage Dashboard', 'Manage Offers', 'Manage Users', 'View Dashboard', 'View Offers', 'View Users'],
      Admin: ['Admin', 'Manage Dashboard', 'Manage Offers', 'Manage Users'],
      User: ['User', 'View Dashboard', 'View Offers', 'View Users'],
    };

    if (roleMappings[selectedRole]) {
      const rolesToAdd = roleMappings[selectedRole];

      if (rolesToAdd.every(role => selectedRoles.includes(role))) {
        setSelectedRoles(selectedRoles.filter(role => !rolesToAdd.includes(role)));
      } else {
        setSelectedRoles([...selectedRoles.filter(role => !rolesToAdd.includes(role)), ...rolesToAdd]);
      }
    } else {
      const updatedRoles = selectedRoles.includes(selectedRole)
        ? selectedRoles.filter(role => role !== selectedRole    )
        : [...selectedRoles, selectedRole];

      setSelectedRoles(updatedRoles);
    }
  };
useEffect(() => {
    const superAdminSubRoles = ['Admin', 'User', 'Manage Dashboard', 'Manage Offers', 'Manage Users', 'View Dashboard', 'View Offers', 'View Users'];
    
    // Check if all sub-roles of SuperAdmin are selected
    const areSuperAdminSubRolesSelected = superAdminSubRoles.every(subRole => selectedRoles.includes(subRole));

    // If all sub-roles are selected and SuperAdmin is not in the array, add it
    if (areSuperAdminSubRolesSelected && !selectedRoles.includes('SuperAdmin')) {
      setSelectedRoles([...selectedRoles, 'SuperAdmin']);
    }
    // If any of the sub-roles is not selected and SuperAdmin is in the array, remove it
    else if (!areSuperAdminSubRolesSelected && selectedRoles.includes('SuperAdmin')) {
      setSelectedRoles(selectedRoles.filter(role => role !== 'SuperAdmin'));
    }
  }, [selectedRoles]); // Run the effect whenever selectedRoles change
 
  const Roles = [
    "SuperAdmin",
    {
      Admin: ["Manage Dashboard", "Manage Offers", "Manage Users"],
    },
    {
      User: ["View Dashboard", "View Offers", "View Users"],
    },
  ];


   

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const validatedData = schema.parse(data);

      const Prosesdata = {
        email: validatedData.email,
        name: validatedData.email.split("@")[0],
        phone: "+21355555555",
        role: selectedRoles[0] || "User",
      };

      setUsers((prevUsers) => [Prosesdata, ...prevUsers]);
      setTimeout(() => {
        setIsAddOpen(false);
      }, 1000);
      console.log(Users)
    } catch (error) {
      console.error(error.errors); // Zod validation errors
    }
  };


  return (
    <Modal
      isOpen={IsAddOpen}
      Style={" w-[90%] lg:w-fit "}
      onClose={() => setIsAddOpen(!IsAddOpen)}
    >
      <img
        className=" cursor-pointer absolute top-3 right-3 z-20 "
        src={closeBtn}
        onClick={() => setIsAddOpen(!IsAddOpen)}
        alt=""
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" relative gap-4   w-[95%] lg:w-[38vw] bg-white rounded-lg items-start justify-around  px-2 lg:px-6 flex flex-col   "
      >
        <h1 className="  text-base lg:text-xl font-semibold ">
        {t('Users.WelcomeANewUserToTheDashboard')}
        </h1>
        <div className="w-full flex  flex-col lg:flex-row gap-3  relative ">
          <div className=" items-start flex flex-col gap-1 w-full">
            <span className=" border-solid border-[1px] justify-center items-center gap-4 pr-2 h-[3rem] flex flex-row  w-full ">
              <input
                {...register("email")}
                placeholder= {t('Users.Email')}
                className={`  h-[3rem] border-[1px] border-r-0  px-3 rounded-md focus-visible:outline-none focus-visible:ring-2    w-full ${
                  errors.email
                    ? "border-red-500 ring-red-500 "
                    : "   ring-main "
                }`}
                type="email"
                name="email"
              />

              <span
                onClick={() => setIsRole(!isRole)}
                className=" font-semibold cursor-pointer  relative flex flex-row gap-2"
              >
                {t('Users.Roles')}  <KeyboardArrowDownIcon />
              </span>
            </span>
            {errors.email && (
              <span className=" text-start text-xs text-red-500">
                {errors.email.message}
              </span>
            )}
          </div>

          <div className=" relative">
            {isRole && (
              <div className=" absolute flex flex-col gap-2 px-2 py-3 w-[11.2rem] top-10 right-2 bg-white shadow-lg  rounded-lg ">
                {Roles.map((role, index) => (
                  <span className="  flex" key={index}>
                    {typeof role === "object" ? (
                      <div className=" flex flex-col gap-4">
                        {Object.keys(role).map((subRole) => (
                          <div key={subRole}>
                            <label
                              htmlFor={subRole}
                              className=" cursor-pointer font-medium  w-full justify-start items-center gap-1 flex  "
                            >
                              <input
                                className=" cursor-pointer accent-main"
                                type="checkbox"
                                name={subRole}
                                id={subRole}
                                checked={selectedRoles.includes(subRole)}
                                onChange={() => handleRoleSelection(subRole)}
                              />
                              <label>{subRole}</label>
                            </label>
                            <span className="       w-full px-3 grid-cols-1  items-start justify-end flex text-sm font-normal   flex-col">
                              {role[subRole].map((subRoleItem, subIndex) => (
                                <span key={subIndex}>
                                  <input
                                    className="accent-main cursor-pointer h-full "
                                    type="checkbox"
                                    name=""
                                    id=""
                                    checked={
                                     
                                      selectedRoles.includes(subRoleItem)
                                    }
                                    onChange={() =>
                                      handleRoleSelection(subRoleItem)
                                    }
                                  />{" "}
                                  {subRoleItem}
                                </span>
                              ))}
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span className=" flex gap-1 font-medium accent-main  ">
                        <input
                          className=" accent-main cursor-pointer"
                          type="checkbox"
                          name=""
                          id=""
                          checked={selectedRoles.includes(role)}
                          onChange={() => handleRoleSelection(role)}
                        />
                        {role}
                      </span>
                    )}
                  </span>
                ))}
              </div>
            )}
          </div>
          <button
            type="submit"
            className="    h-[3rem]  px-7 rounded-md  text-white font-semibold bg-main"
          >
            Invite
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddUsers;
