import React, { useContext, useState, useEffect } from "react";
import userImg from "../../assest/images/userImg.jpg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import GrayLine from "../../Components/UI/GrayLine";
import DeleteUser from "./DeleteUser";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EditUser from "./EditUser.jsx";
import { Usercontext } from "../../context/UserContext";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Tooltip } from "react-tooltip";

function UsersList({ name, role, phone, email, id }) {
  const [IsOption, setIsOption] = useState(false);
  const [IsUserDetails, setIsUserDetails] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [Edit, setEdit] = useState(false);
  const { setUsers, Users } = useContext(Usercontext);

  const [showSkeleton, setShowSkeleton] = useState(true);
  const handelDeletUser = async (id) => {
    setUsers((prevUsers) => prevUsers?.filter((item) => item.id !== id));
  };

  useEffect(() => {
    const skeletonTimeout = setTimeout(() => {
      setShowSkeleton(false);
    }, 1000);

    return () => {
      clearTimeout(skeletonTimeout);
    };
  }, []);

  const handleEditUser = async (id, newRole) => {
    setUsers((prevUsers) => {
      return prevUsers.map((user, index) => {
        if (user.id === id) {
          // If the current user matches the provided id, update the role
          return { ...user, role: newRole };
        }
        return user;
      });
    });
  };
  return (
    <>
      {/* Mobile Veiw */}
      <DeleteUser
        Onclick={() => handelDeletUser(id)}
        Delete={Delete}
        setDelete={setDelete}
      />

      <EditUser
      UserRole={role}
        id={id}
        Onclick={handleEditUser}
        Edit={Edit}
        setEdit={setEdit}
      />
      <div className=" w-full text-xs  lg:hidden flex flex-col">
        <div className=" pt-5    flex justify-between w-full ">
          <span
            onClick={() => setIsUserDetails(!IsUserDetails)}
            className=" font-semibold flex flex-row w-full  items-center gap-2 "
          >
            <ChevronRightOutlinedIcon
              className={` ${
                IsUserDetails ? " rotate-90" : " rotate-0"
              } duration-100 scale-90 text-main`}
            />

            {showSkeleton ? (
              <Skeleton width={40} height={40} circle={true} />
            ) : (
              <img
                className=" h-[2.5rem] w-[2.5rem] object-cover rounded-[50%]  "
                src={userImg}
                alt="userImg"
              />
            )}

            <h1>
              {" "}
              {showSkeleton ? (
                <Skeleton width={100} />
              ) : name.length >= 10 ? (
                `${name.slice(0, 15)}...`
              ) : (
                name
              )}{" "}
            </h1>
          </span>

          <div
            className={`relative  rounded-full text-gray-500 flex flex-row  items-center gap-2`}
          >
            <span
              className={`${
                role === "Super Admin"
                  ? "bg-[#EFFFED] text-[#1FE000]"
                  : role === "Admin"
                  ? "bg-[#EEFFFD] text-[#00DED1]"
                  : role === "User"
                  ? "bg-[#FFF7EF] text-[#f2a75c]"
                  : ""
              } px-3 p-1 rounded-full`}
            >
              {role}
            </span>
            <span
              onClick={() => setIsOption(!IsOption)}
              className=" cursor-pointer"
            >
              <MoreVertOutlinedIcon />
            </span>
            {IsOption && (
              <div className=" z-10 absolute top-4 right-4   bg-white shadow-md rounded-md ">
                <button
                  onClick={() => setEdit(!Edit)}
                  className=" px-2 py-1 items-center flex justify-between gap-1  text-green-600 "
                >
                  <BorderColorOutlinedIcon className=" scale-75 " /> Edit
                </button>
                <GrayLine />
                <button
                  onClick={() => setDelete(!Delete)}
                  className="  items-center flex justify-between gap-1  px-2 py-1 text-red-600 "
                >
                  <DeleteOutlinedIcon className=" scale-75 " /> Delete
                </button>
              </div>
            )}
          </div>
        </div>
        {/*table mobile veiw */}
        {IsUserDetails && (
          <div className="  gap-2 pl-10 pr-5 text-gray-500 py-2 flex flex-col h-full w-full bg-green-50 bg-opacity-50  justify-between ">
            <span className=" flex overflow-auto px-3 pb-1  flex-row gap-4 items-center w-full ">
              <EmailOutlinedIcon className=" scale-75" /> <p> {email} </p>{" "}
            </span>
            <GrayLine />
            <span className=" flex flex-row overflow-auto px-3 pb-1 gap-4 items-center w-full ">
              <LocalPhoneOutlinedIcon className=" scale-75" />{" "}
              <p className=" text-center "> {phone} </p>{" "}
            </span>
          </div>
        )}
      </div>

      {/* table DesckTop Veiw */}
      <div className=" w-full  cursor-default   hidden lg:flex flex-col ">
        <div className=" flex flex-col gap-3 w-full">
          <div className=" pt-5 text-sm   grid grid-cols-5   w-full px-6">
            {/* Name */}
            <span className="flex overflow-auto px-3 pb-1 flex-row gap-2 items-center w-full">
              {showSkeleton ? (
                <Skeleton width={40} height={40} circle={true} />
              ) : (
                <img
                  className="h-[2.5rem] w-[2.5rem] object-cover rounded-[50%]"
                  src={userImg}
                  alt="userImg"
                />
              )}
              <h1 data-tooltip-id="name-tooltip"
              data-tooltip-content={name} className=" cursor-default ">
                {showSkeleton ? (
                  <Skeleton width={100} />
                ) : name.length >= 10 ? (
                  `${name.slice(0, 15)}...`
                ) : (
                  name
                )}
                 <Tooltip className=" font-bold" style={{
                background:'#06c671',
              }} id="name-tooltip" />
              </h1>
            </span>
            {/* Email */}
            <span
              data-tooltip-id="email-tooltip"
              data-tooltip-content={email}
              className="  overflow-auto  text-gray-500 flex flex-row  items-center gap-2 "
            >
              <Tooltip className=" font-bold" style={{
                background:'#06c671',
              }} id="email-tooltip" />
              {showSkeleton ? (
                <Skeleton width={200} />
              ) : email.length >= 40 ? (
                `${email.slice(0, 24)}...`
              ) : (
                email
              )}
            </span>

            {/* Phone */}
            <span className=" pl-16 text-gray-500 flex flex-row  items-center gap-2 ">
              {showSkeleton ? <Skeleton width={100} /> : phone}
            </span>
            {/* Role */}
            <div
              className={` pl-10  rounded-full  text-gray-500 flex flex-row  items-center gap-2`}
            >
              {showSkeleton ? (
                <Skeleton width={100} />
              ) : (
                <span
                  className={`${
                    role === "Super Admin"
                      ? "bg-[#EFFFED] text-[#1FE000]"
                      : role === "Admin"
                      ? "bg-[#EEFFFD] text-[#00DED1]"
                      : role === "User"
                      ? "bg-[#FFF7EF] text-[#f2a75c]"
                      : ""
                  } px-3 p-1 rounded-full`}
                >
                  {role}
                </span>
              )}
            </div>

            {/* Edit or Delete */}
            <div className="  text-gray-500 flex flex-row justify-center  items-center gap-5 ">
              <span onClick={() => setEdit(!Edit)} className=" cursor-pointer">
                <BorderColorOutlinedIcon className=" text-gray-300" />{" "}
              </span>

              <span
                onClick={() => setDelete(!Delete)}
                className=" cursor-pointer"
              >
                <DeleteOutlinedIcon className=" text-red-500" />{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsersList;
