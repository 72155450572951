export const handleSearch = (data, searchTerm, setFilteredData, searchKeys) => {
  const results = data?.filter((item) => {
    // If search term is empty, return all items
    if (!searchTerm) {
      return true;
    }

    // Search in specified keys
    const matches = searchKeys.some((key) =>
      item[key]?.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return matches;
  });
  
  setFilteredData(results);
};