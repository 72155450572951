import React from "react";
import Modal from "../../Components/UI/Modal";
import closeBtn from "../../assest/images/CloseBtn.svg";
import { useTranslation } from "react-i18next";
function ChangePass({ isOpen, setIsOpen }) {
  const {t} = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="  w-[80vw] lg:w-[20vw]   px-4 pt-5  gap-3 flex flex-col ">
        <img onClick={() => setIsOpen(false)} className=" cursor-pointer absolute top-4 right-4" src={closeBtn} alt="" />
        <h1 className=" font-semibold">Change Password</h1>
        <p className=" text-gray-400 text-sm font-semibold">
        {t('Profile.EnterYourCurrentPassword')}
        </p>
        <input
          className=" px-3 w-full h-[3rem] border-[1px] border-solid rounded-md "
          type="password"
          placeholder="current password"
        />

        <p className=" text-gray-400 text-sm font-semibold">
        {t('Profile.EnterTheNewPassword')}
        </p>
        <input
          className=" px-3 w-full h-[3rem] border-[1px] border-solid rounded-md "
          type="password"
          placeholder="new password"
        />

        <p className=" text-gray-400 text-sm font-semibold">
        {t('Profile.ConfirmTheNewPassword')}
        </p>
        <input
          className=" px-3 w-full h-[3rem] border-[1px] border-solid rounded-md "
          type="password"
          placeholder="confirm new password"
        />

        <button className=" w-full h-[3.2rem] bg-main rounded-md text-white  font-semibold text-lg ">
        {t('Profile.Submit')}
        </button>
      </div>
    </Modal>
  );
}

export default ChangePass;
