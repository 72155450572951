import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import WestIcon from "@mui/icons-material/West";
import { Link } from "react-router-dom";
import StepOne from "./Registration/StepOne";
import StepTwo from "./Registration/StepTwo";

function Registration() {
  const [step, setStep] = useState(0);
  return (
    <div className=" justify-between relative  w-full  px-[1px]  md:px-[7%] flex h-[100vh]   text-start flex-col   pt-12  lg:pt-0 ">
      <div>
        <span className=" absolute top-3 flex lg:hidden  left-0">
          <WestIcon />
        </span>
        <div className=" flex flex-col text-center  lg:text-left ">
          <h1 className=" text-2xl font-semibold ">Welcome to Fintechracy</h1>
          <p className=" pt-4 font-semibold pb-10 text-gray-400 ">
            Enter your information to create an account{" "}
          </p>
        </div>
        {step == 0 ? (
          <StepOne setStep={setStep} />
        ) : (
          <StepTwo setStep={setStep} />
        )}
      </div>
      <div className=" w-full items-center justify-center pt-3 text-gray-400 font-semibold flex flex-row gap-1 ">
        <p>Already have an account ?</p>
        <Link to={"/Auth"} className=" text-main">
          Sign in
        </Link>
      </div>
    </div>
  );
}

export default Registration;
