import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Offers from "./Offers.jsx";
import Profile from "./Profile.jsx";
import { UseToggle } from "../hooks/UseToggle";
import Users from "./Users.jsx";
import Logo from "../assest/images/logoW.png";
import SearchIcon from "@mui/icons-material/Search";
import { Usercontext } from "../context/UserContext.js";
import user from "../assest/images/userImg.jpg";
import ContactSupport from "./ContactSupport.jsx";
import Sidbar from "../Components/Index/SidBar.jsx";
import LinksComponent from "../assest/data/Links.js";
function Index() {
  const { pathname } = useLocation();
  const [isSidBarOpen, setIsSidBarOpen] = useState(false);
  const { User, setUser } = useContext(Usercontext);
  const links = LinksComponent();
  return (
    <div className=" flex w-full  bg-slate-100 ">
      {/* Sid Bar */}
      <Sidbar isSidBarOpen={isSidBarOpen} setIsSidBarOpen={setIsSidBarOpen} />

      <div className=" flex w-full  flex-col  gap-0 lg:gap-4  ">
        <div className=" w-full  lg:pt-0 pt-5  lg:h-[5rem]  bg-white flex  items-center justify-between  px-3 lg:px-8  ">
          <img
            className=" flex lg:hidden  w-[150px] h-[35px]   "
            src={Logo}
            alt=""
          />
          <span className=" h-[3.5rem] items-center lg:hidden flex "> </span>
          <div className=" flex flex-row gap-5  items-center">
            <p className=" lg:flex hidden    font-semibold ">
              {" "}
              {links.find((link) => link.link == pathname)?.text}
            </p>
          </div>

          <div className="  lg:bg-white bg-main  justify-center  flex items-center gap-3 p-1 rounded-full flex-row">
            <div
              onClick={() => setIsSidBarOpen(!isSidBarOpen)}
              className="text-white  lg:hidden flex flex-row gap-2 p-2 rounded-full "
            >
              <MenuIcon />
            </div>
           <Link to={'/profile'} className=" flex flex-row gap-2" >
            <img
              className="  h-[2.3rem] w-[2.3rem]  lg:h-[3.3rem]  lg:w-[3.3rem]  rounded-[50%]  object-cover  "
              src={User?.image}
              alt=""
            />
            <span className=" text-start  hidden  lg:flex flex-col ">
              <h1 className=" font-semibold ">{" "}
                {User?.name?.length >= 16
                  ? `${User?.name.slice(0, 16)}`
                  : User?.name}{" "}
               </h1>
              <p className=" text-gray-400 text-sm "> {User?.company} </p>
            </span>
           </Link>

          </div>
        </div>

        {/* Link name mob view */}
        <div className=" w-full  py-3   text-base  font-bold lg:hidden flex  text-left px-3 bg-white  ">
          {links.find((link) => link.link == pathname)?.text}{" "}
        </div>
        {/* Pages */}
        <div className="  lg:ml-4 h-full w-full">
          {pathname == "/offers" && <Offers />}
          {pathname == "/" && <Users />}
          {pathname == "/profile" && <Profile />}
          {pathname == "/contactSupport" && <ContactSupport />}
        </div>
      </div>
    </div>
  );
}

export default Index;
