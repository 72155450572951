import {React , useEffect } from "react";

const Modal = ({ isOpen, onClose, children, Style }) => {

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <div
      className={`duration-150 ${
        isOpen ? "top-[50%]" : " top-[-100%] -z-10 "
      } flex z-[100] fixed h-full w-full   `}
    >
      {/* Overlay for background dimming */}
      <div
        onClick={onClose}
        className={`flex fixed h-full w-full   ${
          isOpen ? "opacity-50 top-0  " : "opacity-0  top-[-100%] -z-10  "
        } right-0 bg-black `}
      ></div>

      <div
        className={`${
          isOpen
            ? "z-30 top-[50%] opacity-100 "
            : " opacity-0 -top-[50%] -z-10    "
        } z-[60] duration-150 transform flex  translate-x-[-50%] translate-y-[-50%]   left-[50%] fixed ${Style}  flex-col items-center justify-between pt-[1.6rem] pb-[2rem] px-[.74rem] rounded-[1rem]  bg-white `}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
