import React, { useContext, useState } from "react";
import user from "../assest/images/userImg.jpg";
import GrayLine from "../Components/UI/GrayLine";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import FileDragZone from "../Components/Offer/FileDragZone";
import { Usercontext } from "../context/UserContext";
import ChangePass from "../Components/Profile/ChangePass";
import ChangeEmail from "../Components/Profile/ChangeEmail.jsx";
import LoadingBtn from "../Components/UI/LoadingBtn.jsx";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

function Profile() {
  const { User, setUser, setMsg } = useContext(Usercontext);
  const [name, setName] = useState(User?.name);
  const [company, setCompany] = useState(User?.company);
  const [codePostal, setCodePostal] = useState(User?.codePostal);
  const [contry, setContry] = useState(User?.contry);
  const [email, setEmail] = useState(User?.businessemail);
  const [file, setFile] = useState(null);
  const {t} = useTranslation()
  const [ChangePassOpen, setChangePassOpen] = useState(false);
  const [ChangeEmailOpen, setChangeEmailOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  function Cancel() {
    setName(User?.name);
    setCompany(User?.company);
  }

  function AcceptChanges() {
    if (company && name && contry && codePostal) {
      setIsLoading(true);
      // fake api
      setTimeout(() => {
        setUser({
          name: name,
          company: company,
          contry: contry,
          codePostal: codePostal,
          image: file ? URL.createObjectURL(file[0]) : user,
        });
        setMsg(["secess", "Your information has been updated successfully"]);
        setIsLoading(false);
      }, 2000);
    } else {
      alert("please fill all fildes");
    }
  }
  const handleFileDrop = (acceptedFiles) => {
    console.log("Dropped files:", acceptedFiles);
    setFile(acceptedFiles);
  };

  return (
    <div className=" text-left bg-white flex flex-col gap-5 w-full pb-10 h-full">
      <div className=" flex flex-row w-full justify-between  items-end   px-[1rem] lg:px-10   pt-3 lg:pt-10 ">
        <div className="  flex flex-col justify-between gap-10 ">
          <span className=" flex  flex-row gap-4  lg:items-center">
            <img
              className=" rounded-[50%] object-cover   w-[4rem] h-[4rem] lg:w-[6rem]  lg:h-[6rem] "
              src={User?.image}
              alt=""
            />
            <span className=" flex flex-col items-start">
              <h1 className="  font-semibold lg:font-bold">
                {" "}
                {User?.name?.length >= 16
                  ? `${User?.name.slice(0, 16)}`
                  : User?.name}{" "}
              </h1>
              <p className=" text-gray-400">{User?.company}</p>
            </span>
          </span>
          <span className=" flex flex-col items-start ">
            <h1 className=" font-semibold"> {t('Profile.Information')} </h1>
            <p className=" text-gray-400  text-xs  lg:text-sm ">
            {t('Profile.UpdateYourPersonalAndYourCompanyInformationHere')}
            </p>
          </span>
        </div>
        <span className="  hidden lg:flex flex-row gap-3 ">
          <button
            onClick={Cancel}
            className=" px-3 p-2 rounded-md bg-gray-200 text-gray-400 "
          >
            {t('Profile.Cancel')}
          </button>
          <button
            onClick={AcceptChanges}
            className={` ${
              isLoading ? " opacity-50" : " opacity-100"
            } flex items-center justify-center  w-[8rem] px-3 p-2 rounded-md text-white bg-main`}
          >
            {isLoading ? (
              <Oval
                visible={isLoading}
                height="25"
                width="25"
                color="white"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              t('Profile.SaveChanges')
            )}
          </button>
        </span>
      </div>
      <GrayLine />
      {/* Basic Details */}
      <div className="    w-full lg:w-[70%]  justify-between  flex-col  lg:gap-0 gap-6  lg:flex-row flex  px-[1rem] lg:px-10 pt-5">
        <span className=" w-full flex flex-col ">
          <h1 className=" font-semibold">   {t('Profile.BasicDetails')} </h1>
          <p className=" text-gray-400  text-xs lg:text-sm">
          {t('Profile.ChangeProfilePictureAndFullName')}
          </p>
        </span>
        {/* Image  */}
        <div className=" flex  flex-col  gap-[1.3rem]    w-full   ">
          <span className="   flex-col-reverse  lg:items-start items-center flex  lg:flex-row  gap-6 ">
            <span className="  w-full lg:w-[15rem]  h-[10rem] lg:h-[8rem]  ">
              <FileDragZone
                onFileDrop={handleFileDrop}
                profile={true}
                file={file}
              />
            </span>
            <img
              className=" rounded-[50%] object-cover  w-[6rem] h-[6rem] "
              src={file ? URL.createObjectURL(file[0]) : user}
              alt=""
            />
          </span>
          {/* Name Input */}
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your Name"
            className="   px-3 border-solid border-[1px] rounded-md  h-[3rem]  "
            type="text"
          />
        </div>
      </div>
      <GrayLine />
      {/* Country & Zip code */}
      <div className="   w-full lg:w-[70%] justify-between  lg:gap-0  gap-6 flex  flex-col lg:flex-row px-[1rem] lg:px-10 pt-5">
        <h1 className=" font-semibold">  {t('Profile.CountryAndZipCode')} </h1>
        <span className="   w-full lg:w-[50%] flex flex-col gap-4">
          <input
            value={contry}
            onChange={(e) => setContry(e.target.value)}
            placeholder=""
            className="   px-3 border-solid border-[1px] rounded-md  h-[3rem]  "
            type="text"
          />

          <input
            value={codePostal}
            onChange={(e) => setCodePostal(e.target.value)}
            placeholder=""
            className="   px-3 border-solid border-[1px] rounded-md  h-[3rem]  "
            type="text"
          />
        </span>
      </div>
      <GrayLine />
      {/* Business Email */}
      <div className=" flex   w-full lg:w-[77%] justify-between  lg:gap-0  gap-6  flex-col lg:flex-row px-[1rem] lg:px-10 pt-5  ">
        <h1 className=" font-semibold">{t('Profile.BusinessEmail')} </h1>
        <span className="  w-full lg:w-[55%] flex flex-col items-start gap-3  lg:flex-row">
          <input
            disabled={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="chaouki.gueddou@fintechracy.com"
            className="  text-gray-400  w-full  px-3 border-solid border-[1px] rounded-md  h-[3rem]  "
            type="email"
          />
          <button
            onClick={() => setChangeEmailOpen(true)}
            className=" text-xs text-gray-400 underline"
          >
            <DriveFileRenameOutlineIcon className=" scale-75" /> {t('Profile.ChangeEmail')}
          </button>
        </span>
      </div>
      <GrayLine />
      {/* Password */}
      <div className=" flex   w-full lg:w-[77%] justify-between  lg:gap-0  gap-6  flex-col lg:flex-row px-[1rem] lg:px-10 pt-5  ">
        <h1 className=" font-semibold"> {t('Profile.Password')}</h1>
        <span className="  w-full lg:w-[55%] flex flex-col items-start gap-3  lg:flex-row">
          <input
            disabled={true}
            placeholder="chaouki.gueddou@fintechracy.com"
            className="   w-full  px-3 border-solid border-[1px] rounded-md h-[3rem]  "
            type="password"
            value={"testtest"}
          />
          <button
            onClick={() => setChangePassOpen(true)}
            className=" text-xs text-gray-400 underline"
          >
            <DriveFileRenameOutlineIcon className=" scale-75" /> {t('Profile.ChangePassword')}
          </button>
        </span>
      </div>

      <GrayLine />
      {/* Capabilities */}
      <div className=" flex gap-[2rem]   flex-col px-[1rem] lg:px-10 pt-5  w-full ">
        <h1 className=" font-semibold"> {t('Profile.Capabilities')}</h1>
        <span className="  w-full lg:w-[37%]  flex flex-row gap-3 ">
          <h1 className="  px-2 p-1 md:px-3  md:p-2 text-sm  rounded-full bg-[#EEFFFD]  text-[#00DED1]  ">
          {t('Profile.ManageOffers')}
          </h1>
          <h1 className=" px-2 p-1 md:px-3  md:p-2 text-sm   rounded-full bg-[#FFF7EF]  text-[#FF6B00]  ">
          {t('Profile.ManageUsers')}  
          </h1>
          <h1 className=" px-2 p-1  md:px-3   md:p-2 text-sm  rounded-full bg-[#FEFFB5]  text-[#BBBF00]  ">
          {t('Profile.ViewOffers')}  
          </h1>
        </span>
      </div>

      {/* Mobile Veiw */}
      <span className=" flex px-5 lg:hidden w-full items-center justify-center pt-5 flex-row gap-3 ">
        <button
          onClick={Cancel}
          className=" px-3 w-full p-2 rounded-md bg-gray-200 text-gray-400 "
        >
          {t('Profile.Cancel')}
        </button>
        <button
          onClick={AcceptChanges}
          className={` ${
            isLoading ? " opacity-50" : " opacity-100"
          } px-3 p-2 w-full flex items-center justify-center rounded-md text-white bg-main `}
        >
          {isLoading ? (
            <Oval
              visible={isLoading}
              height="30"
              width="30"
              color="white"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          ) : (
            t('Profile.SaveChanges')
          )}
        </button>
      </span>

      <ChangePass isOpen={ChangePassOpen} setIsOpen={setChangePassOpen} />
      <ChangeEmail isOpen={ChangeEmailOpen} setIsOpen={setChangeEmailOpen} />
    </div>
  );
}

export default Profile;
