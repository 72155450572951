import React, { useContext, useRef, useState } from "react";
import offer from "../../assest/images/offer.jpg";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Grayline from "../UI/GrayLine";
import { useClickOutside } from "../../hooks/UseClickOutSide";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteOffer from "../Offer/DeleteOffer";
function Offer({
  name,
  Link,
  IsAstory,
  SelectOfferToEdit,
  setOffers,
  period,
  lessfor,
  id,
  setShowDetails,
  img,
}) {
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const OptionRef = useRef();
  useClickOutside(OptionRef, () => setIsOptionOpen(false));

  const handelDeletOffer = async (id) => {
    setOffers((prevOffers) => prevOffers?.filter((item) => item.id !== id));
  };

  return (
    <>
      <DeleteOffer
        Onclick={() => handelDeletOffer(id)}
        Delete={isDelete}
        setDelete={setIsDelete}
      />
      <div className=" relative text-left flex flex-col w-full h-[13rem] cursor-pointer gap-4 rounded-lg   border-solid border-[1px] ">
        <span
          ref={OptionRef}
          onClick={() => setIsOptionOpen(!isOptionOpen)}
          className=" absolute top-2 right-2 bg-black bg-opacity-40 text-white w-[1.5rem] rounded-[50%] h-[1.5rem] flex  items-center justify-center "
        >
          <MoreVertOutlinedIcon />
        </span>
       {/* Option to delete or edit */}
        {isOptionOpen && (
          <div className="    shadow-xl rounded-md absolute   top-8 right-2 bg-white flex flex-col  ">
            <p
              onClick={() => SelectOfferToEdit(id)}
              className="hover:bg-slate-100  rounded-lg py-1 flex gap-1 items-center px-2   text-green-500  "
            >
              {" "}
              <DriveFileRenameOutlineOutlinedIcon className=" scale-75 " />
              edit
            </p>
            <Grayline />
            <p
              onClick={() => setIsDelete(true)}
              className="hover:bg-slate-100 py-1 flex gap-1 items-center  rounded-lg px-2 text-red-500"
            >
              <DeleteOutlineOutlinedIcon className=" scale-75 " /> delete
            </p>
          </div>
        )}
        <img
          onClick={() => setShowDetails(id)}
          className=" w-full h-[65%] rounded-md object-cover  "
          src={img || offer}
          alt=""
        />
        <span className=" px-4">
          <h1 className=" font-semibold">{name}</h1>
          <h1 className="  text-sm text-gray-500">
            available for {lessfor} days
          </h1>
        </span>
      </div>
    </>
  );
}

export default Offer;
