import profile from "./profile.svg";
import profileSlected from "./profileSlected.svg";
import contact from "./contact.svg";
import contactSlected from "./contactSlected.svg";
import offers from "./offers.svg";
import offersSlected from "./offersSlected.svg";
import get_started from "./get_started.svg";
import get_startedSlected from "./get_startedSlected.svg";
import dashboard from "./dashboard.svg";
import dashboardSlected from "./dashboardSlected.svg";
import users from "./users_1.svg";
import users_1Slected from "./users_1Slected.svg";

export const OfferImages = {
  
  profile,
  profileSlected,
  
  contact,
  contactSlected,

  offers,
  offersSlected,
  
  get_started,
  get_startedSlected,
  
  dashboard,
  dashboardSlected,

  users,
  users_1Slected,
};
