import React from 'react'
import ImgGoogle from "../assest/images/AuthImages/Google.svg";
import Microsoft from "../assest/images/AuthImages/Microsoft.svg";
import user from "../assest/images/AuthImages/users.svg";
import Registration from '../Components/Auth/Registration';
import SignIn from '../Components/Auth/SignIn';
import ResetPassword from '../Components/Auth/ResetPassword.jsx';
import VerificationCode from '../Components/Auth/VerificationCode.jsx';
import ChangePass from '../Components/Auth/ChangePass.jsx';
import { useLocation } from 'react-router-dom';


function Auth() {
 
    const {pathname} = useLocation()

  return (
    <div className=" flex flex-row items-center  p-4 gap-5    h-full">
    <div className="  text-left   w-[80%] bg-main text-white  rounded-md  hidden  lg:flex flex-col  gap-16   px-[5%] py-10 h-full ">
      <h1 className=" text-4xl font-bold  ">
        Seamless, Sustainable <br /> and Smart.
      </h1>
      <p className=" text-lg">
        Our user-friendly dashboard empowers your business to effortlessly
        manage and access digital receipts, eliminating the need for paper and
        streamlining your record-keeping process
      </p>
      <img className=" w-full  h-full  " src={user} alt="" />
      <div className=" flex justify-between w-full    ">
        <img className=" w-full  h-[6rem]  " src={ImgGoogle} alt="" />
        <img className=" w-full  h-[6rem]  " src={Microsoft} alt="" />
      </div>
    </div>
    {/* Left Side */}

    {
        pathname == '/Auth/SignUp' &&
        <Registration/>
    }
    
    {
        pathname == '/Auth' &&
        <SignIn/>
    }

{
        pathname == '/Auth/ResetPassword' &&
        <ResetPassword/>
    }

{
        pathname == '/Auth/VerificationCode' &&
        <VerificationCode/>
    }

    
{
        pathname == '/Auth/ChangePass' &&
        <ChangePass/>
    }

  </div>
  )
}

export default Auth