import React from "react";
import Modal from "../UI/Modal";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../assest/images/delete_user.svg";
function DeleteUser({ Delete, setDelete , Onclick   }) {
const {t} = useTranslation()

  return (
    <Modal
      isOpen={Delete}
      Style={"  "}
      onClose={() => setDelete(!Delete)}
    >
      <div className=" flex w-[60vw]  lg:w-[20vw] h-[18rem]  lg:h-[20rem]   p-3 items-center justify-between flex-col gap-3">
        <img className=" h-[9rem]  w-[9rem] lg:w-[12rem]  lg:h-[12rem] " src={deleteIcon} alt="" />
        <h1 className=" text-lg font-bold"> {t('Users.DeleteUser')}  </h1>
        <p className=" text-gray-500">
        {t('Users.AreYouSureYouWantToDeleteThisUser')}
        </p>
        <div onClick={() => setDelete(!Delete)} className=" flex  pt-2 flex-row gap-4 w-full  font-semibold justify-between">
            <button className=" p-2 w-full rounded-md  bg-gray-200 text-gray-400  "> {t('Users.No')}</button>
            <button onClick={Onclick} className=" p-2  w-full rounded-md bg-red-600    text-white  ">{t('Users.Yes')}</button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteUser;
