import React from "react";
import Modal from "../UI/Modal";
import delete_offer from "../../assest/images/Offers/delete_offer.svg";
import { useTranslation } from "react-i18next";
function DeleteOffer({ Delete, setDelete , Onclick   }) {

  const { t } = useTranslation(); // Hook for translation

  return (
    <Modal
      isOpen={Delete}
      Style={"  "}
      onClose={() => setDelete(!Delete)}
    >
      <div className=" flex w-[60vw]  lg:w-[20vw] h-[18rem]  lg:h-[20rem]  p-3 items-center justify-between flex-col gap-3">
        <img className=" h-[9rem]  w-[9rem] lg:w-[12rem]  lg:h-[12rem] " src={delete_offer} alt="" />
        <h1 className=" text-lg font-bold">  {t('Offers.DeleteOffer')} </h1>
        <p className=" text-gray-500">
        {t('Offers.AreYouSureYouWantToDeleteThisOffer')}
        </p>
        <div onClick={() => setDelete(!Delete)} className=" flex  pt-2 flex-row gap-4 w-full  font-semibold justify-between">
            <button className=" p-2 w-full rounded-md  bg-gray-200 text-gray-400  ">No</button>
            <button onClick={Onclick} className=" p-2  w-full rounded-md bg-red-600    text-white  ">Yes</button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteOffer;
