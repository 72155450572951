import { React, useState, useRef, useEffect, useContext } from "react";
import { useClickOutside } from "../hooks/UseClickOutSide";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddMoreOffers from "../Components/Offer/AddMoreOffers";
import Offer from "../Components/Offer/Offer";
import OfferDeteils from "../Components/Offer/OfferDeteils";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { OffersData } from "../assest/OffersData";
import SearchIcon from "@mui/icons-material/Search";
import { handleSearch } from "../utils/Functions/Search";
import { useTranslation } from "react-i18next";

function Offers() {
  const [Period, setPeriod] = useState("Today");
  const [PerOpen, setPerOpen] = useState(false);
  const [isAddOfferOpen, setIsAddOfferOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [Offers, setOffers] = useState(OffersData);
  const [FiltredOffers, setFiltredOffers] = useState();

  const [SelectedOffer, setSelectedOffer] = useState(null);

  const PeriodRef = useRef();
  const closePeriod = () => {
    setPerOpen(false);
  };
  useClickOutside(PeriodRef, closePeriod);


  const { t } = useTranslation(); // Hook for translation
  useEffect(()=>{setFiltredOffers(Offers) } , [Offers] )
   
  const PeriodArray = [
    { text: "Today", value: "Today" },
    { text: "Yesterday", value: "Yesterday" },
    { text: "This week", value: "Thisweek" },
    { text: "Last week", value: "Lastweek" },
    { text: "This month", value: "Thismonth" },
    { text: "Last month", value: "Lastmonth" },
    { text: "This year", value: "Thisyear" },
    { text: "Last year", value: "Lastyear" },
  ];

  useEffect(() => {
    const currentDate = new Date();
    let startDate = new Date();

    if (Period === "Today") {
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "Yesterday") {
      startDate.setDate(currentDate.getDate() - 1);
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "This week") {
      startDate.setDate(currentDate.getDate() - currentDate.getDay());
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "Last week") {
      startDate.setDate(currentDate.getDate() - 7 - currentDate.getDay());
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "This month") {
      startDate.setDate(currentDate.getDate() - 29); // Last 30 days
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "Last month") {
      startDate.setDate(currentDate.getDate() - 59); // Days between 30 and 60 days ago
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "This year") {
      startDate.setMonth(0, 1);
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "Last year") {
      startDate.setFullYear(currentDate.getFullYear() - 1, 0, 1);
      startDate.setHours(0, 0, 0, 0);
    }

    // Filtring the Receipts by the Selected Period
    const filteredData = OffersData?.filter((item) => {
      const itemDate = new Date(item.startDate);
      return itemDate >= startDate;
    });
    console.log(Period);
    console.log(filteredData);
  }, [Period]);

  const addOffer = (newOffer) => {
    // setIsLoading(true)

    const highestId = Math.max(...Offers.map((item) => item.id));
    const updatedOffer = {
      id: highestId + 1,
      // Assuming newOffer contains other properties like name, period, etc.
      ...newOffer,
    };
    setOffers((prevOffers) => [updatedOffer, ...prevOffers]);
  };

  // Function to edit an offer by ID
  const editOffer = (offerId, updatedOffer) => {
    console.log(updatedOffer)
    console.log(offerId)
    setOffers((prevOffers) =>
      prevOffers.map((offer) => (offer.id === offerId ? updatedOffer : offer))
    );
  };

  const itemsPerPage = 24; // Adjust the number of items to display per page

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = Offers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(Offers.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Selecting the Offer to Edit
  const SelectOfferToEdit = (id) => {
    const SelectedOffer = Offers.find((offer) => offer.id == id);
    setSelectedOffer(SelectedOffer);
    setIsAddOfferOpen(true);
    setShowDetails(false);
  };

  const searchKeysForReceipts = ["name"];

  const handelSearchInput = (value) => {
    handleSearch(Offers, value, setFiltredOffers, searchKeysForReceipts);
  };

  return (
    <div className="  relative w-full flex flex-row  gap-0 lg:gap-4 h-full overflow-auto ">
      <div
        className={` ${
          isAddOfferOpen || showDetails ? " lg:flex hidden" : " flex"
        } bg-white pt-5 px-5 w-full flex flex-col rounded-md gap-7`}
      >
        <div
          className={`  items-center w-full flex flex-row justify-between  gap-4`}
        >
          <h1 className=" text-lg  hidden  lg:flex  font-semibold ">
          {t('Offers.RecentOffers')}
          </h1>
          <span className="  lg:w-[50vh] h-[3rem]   w-full relative">
            <SearchIcon className=" absolute top-[.9rem] scale-[.9] left-2 text-gray-400 " />
            <input
              onChange={(e) => handelSearchInput(e.target.value)}
              placeholder={t('Offers.SearchHere')}
              className="  px-9     h-full w-full  border-solid rounded-md border-[1px]   "
            />
          </span>

          {/* Period Filtering */}
          <span
            onClick={() => setPerOpen(!PerOpen)}
            ref={PeriodRef}
            className={`relative flex flex-row  cursor-pointer  pl-2  w-[50%] lg:w-[15%] h-[3.2rem]     justify-around   border-solid border-[1px]  rounded-full   items-center  `}
          >
            <h1 className=" text-[.9rem] font-[500] ">
              {" "}
              {PeriodArray.find((i) => i.value == Period).text}{" "}
            </h1>
            <span className=" text-main">
              {" "}
              <KeyboardArrowDownIcon
                className={` ${
                  PerOpen ? " -rotate-180" : " rotate-0"
                } duration-150 `}
              />{" "}
            </span>
            <div
              className={` duration-150  bg-[#F9F9FF]  z-40 flex flex-col  top-14 right-0 rounded w-full absolute   ${
                PerOpen ? ` h-[19.4rem]   ` : " h-0"
              }   shadow overflow-hidden `}
            >
              {PeriodArray.map((i) => (
                <div className=" flex text-[.9rem] flex-col">
                  <div
                    onClick={() => setPeriod(i.value)}
                    className={`  ${
                      Period == i.value ? " text-main" : ""
                    }  p-2 `}
                  >
                    {i.text}
                  </div>
                  <span className={`bg-[#e9e7e7] w-full h-[1px]    `}></span>
                </div>
              ))}
            </div>
          </span>
        </div>

        {/* Listing all the offers */}
        <div
          className={`grid ${
            isAddOfferOpen || showDetails
              ? "   sm:grid-cols-4"
              : "  md:grid-cols-4 2xl:grid-cols-6"
          } gap-3   `}
        >
          {FiltredOffers?.map((item) => (
            <Offer
              SelectOfferToEdit={SelectOfferToEdit}
              setOffers={setOffers}
              setShowDetails={setShowDetails}
              id={item.id}
              img={item.img}
              name={item.name}
              Link={item.Link}
              lessfor={item.lessfor}
              IsAstory={item.IsAstory}
              period={item.period}
            />
          ))}
        </div>
        {/* Offers Pagination */}
        <div className=" py-10 text-main">
          <button
            className={` ${
              currentPage === 1 ? " opacity-25 " : " opacity-100"
            } `}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <NavigateNextOutlinedIcon className=" rotate-180" />
          </button>
          <span className=" text-gray-800"> {currentPage} </span>
          <button
            className={` ${
              currentPage === totalPages ? " opacity-25 " : " opacity-100"
            } `}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <NavigateNextOutlinedIcon />
          </button>
        </div>
      </div>
      {/* The right sid bar OfferDeteils or AddMoreOffers */}
      <div
        className={` mr-2 relative rounded-md ${
          isAddOfferOpen || showDetails
            ? "   w-full lg:w-[45%]"
            : "  w-0 lg:w-[8%] "
        }   flex justify-center items-center  sticky top-0  duration-200 bg-white h-full`}
      >
        {showDetails ? (
          <OfferDeteils
            img={Offers.find((item) => item.id == showDetails)?.img}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            name={Offers.find((item) => item.id == showDetails)?.name}
            startDate={Offers.find((item) => item.id == showDetails)?.startDate}
            endDate={Offers.find((item) => item.id == showDetails)?.endDate}
            Link={Offers.find((item) => item.id == showDetails)?.Link}
            IsAstory={Offers.find((item) => item.id == showDetails)?.IsAstory}
          />
        ) : (
          <AddMoreOffers
          editOffer={editOffer}
            SelectedOffer={SelectedOffer}
            setSelectedOffer={setSelectedOffer}
            addOffer={addOffer}
            isAddOfferOpen={isAddOfferOpen}
            setIsAddOfferOpen={setIsAddOfferOpen}
          />
        )}
      </div>
    </div>
  );
}

export default Offers;
